// Less Function

/* 多行文字超出省略号 (行数) */
.more-line(@line) when (isnumber(@line)) {
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: @line;
	-webkit-box-orient: vertical;
}
