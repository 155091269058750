.modal {
    position: fixed;
    right: 0;
    top: 65px;
    height: calc(100vh - 65px);
    z-index: 1000;
    overflow: hidden;

    /* 遮罩 */
    .modal-mask-warp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .modal-mask-show {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
        transition: opacity 0.5s;
    }

    .modal-mask-hide {
        opacity: 0;
        transition: opacity 0.5s;
    }

    /* 弹框 */
    .modal-main {
        position: absolute;
        max-height: calc(100vh - 65px);
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #fff;
        box-shadow: 0px 5px 12px 3px rgba(33, 34, 34, 0.1);
        border-radius: 10px;

        /* 其他样式 */
        .modal-head {
            width: 100%;
            height: 55px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            padding: 5px 26px 0px 15px;
            font-size: 16px;
            color: #434343;
            font-weight: 400;
            border-bottom: 1px solid #bfbfbf;
            border-radius: 10px 10px 0 0;
            

            .close-btn {
                display: inline-block;
                cursor: pointer;
                height: 100%;
                color: #707070;
                font-weight: 400;
            }
        }

        .modal-body {
            position: relative;
            padding-top: 5px;
            height: calc(100% - 100px);
            overflow-y: auto;
            color: #707070;
            margin:0 20px
        }

        .modal-footer {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            padding: 5px 10px;
            border-top: 1px solid #bfbfbf;
            border-radius: 0 0 10px 10px;
            background-color: #fff;

            span {
                display: block;
                width: 88px;
                min-width: 60px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                border-radius: 5px;
                cursor: pointer;
                background: #D6D6D6;
                margin-left: 35px;

                &:last-child {
                    background: #D6D6D6;
                    margin-right: 0;
                    color: #010002;
                }
            }

            .submit-button {
                background: #3b62e8;
                color: #fff;
            }
        }
    }
}
@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;