.detail-nav{
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  &-title{
font-size: 17px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bolder;
color: #333333;
line-height: 22px;
  }
}
@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;