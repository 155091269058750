// input
.ant-input {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    &-affix-wrapper {
        padding: 0px 11px !important;
    }
}

// select
// .ant-select {
//     width: 100%;
//     height: 32px;
//     line-height: 32px;
//     font-size: 14px;
//     vertical-align: middle;
//     // &-selector {
//     //     height: 32px;
//     //     line-height: 32px;
//     // }
//     &-single&-sm:not(.ant-select-customize-input) .ant-select-selector {
//         height: 32px;
//         line-height: 32px;
//     }
//     &:not(.ant-select-customize-input) .ant-select-selector {
//         min-height: 32px; // min-width:212px;
//     }
// }

.ant-picker {
    width: 100%;
    height: 34px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    &-input > input {
        font-size: 14px !important;
    }
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
    margin-left: 2px;
}

.ant-form-item {
    margin-bottom: 10px;
    &-label > label {
        font-size: 15px;
        color: #333;
    }
}

.ant-tabs {
    font-size: 14px;
    // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    //   color: #3258FF ;
    //   font-weight: 600;
    // }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000000;
        font-weight: 600;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 22px;
    }
}

//td 按钮容器
.td-wrap {
    padding: 0 !important;
}

//增加编辑表格 鼠标滑过样式
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    min-height: 30px;
    line-height: 22px;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    span {
        padding: 0;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
    }
}
.ant-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #323232;
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    margin-bottom: 15px;
}

//   .ant-input-number-handler-wrap {
//     display: none
// }

.ant-input-affix-wrapper .ant-input:hover {
    border: none;
}
