.import-item {
    width: 770px;
    height: 110px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f4f4f4;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .import-item-icon-box {
        width: 110px;
        height: 110px;
        background: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        .import-item-icon {
            // width: 51px;
            // height: 38px;
            font-size: 51px;
        }
    }

    .important-item-main {
        flex: 1;
        padding: 10px 22px;
        .important-item-main-title {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
        }
        .important-item-main-info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
        }
        .important-item-main-btn {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4165e3;
            line-height: 20px;
            cursor: pointer;
          
        }
    }
}


@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;