#offer_pdf{
    // line-height: 1;
    padding:10px 16px;
    color: #000;
    header{
        position: relative;
        text-align: center;
        padding-top: 20px;
        .logo{
            width: 60px;
            height: 60px;
            position: absolute;
            left: 30px;
            top: 0;
            img{
                width: 100%;
                height:100%;
            }
        }
    }
    .description{
        padding:0;
        li{
            // display: inline-block;
            // width: 50%;
            padding-left:10px;
            vertical-align: bottom;
        }
    }
    .title{
        color:blue;
        text-align: center;
        font-size: 22px;
    }
    .order_info{
        padding:0;
        li{
            vertical-align: bottom;
            border-bottom: 1px dashed;
            margin-bottom: 6px;
        }
    }

    .material_table {
        margin: 0;
        width: 100%;
        td,
        th {
            border: 1px solid #000;
            padding: 5px 15px;
        }
      
    }
    li.w70{
        display: inline-block;
        width: 70%;
    }
    li.w30{
        display: inline-block;
        width: 30%;
    }
}