.list-container {
  background: #FFFFFF;
  border-radius: 4px;
  // box-shadow: 0 5px 12px 3px rgba(33, 34, 34, 0.1);
  overflow: hidden;
  padding: 20px 20px 0px 20px;
  // height: calc(100vh - 210px);

  &.inline-block {
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.list-header {
  padding: 0 27px 0 15px;
  white-space: nowrap;
  color: @color-dark-4;

  &-title {
    margin-bottom: 10px;
    color: @color-dark-4;
    font-weight: 400;
  }

  &-content {

    margin-bottom: 10px;

    &-item {
      font-size: 14px;
      margin-left: 60px;
      font-weight: 400;
    }

    &-statu-item {
      font-size: 14px;
      margin-right: 50px;
      font-weight: 400;
    }

    &-money {
      font-size: @font-size-lg;
    }

    &-statu {
      font-size: @font-size-lg;
    }
  }


}

.list-buttons {
  // padding: 0 15px 20px;
  padding: 0 0 20px 0;
}
@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;