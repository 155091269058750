.yh-form-box {

    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    height: auto;
    margin-bottom: -5px !important;
    &-header {
        height: 19px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
        }

        &-btn {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4165e3;
            line-height: 16px;
            cursor: pointer;
            > span {
                font-size: 14px;
                margin-left: 20px;
            }
        }
    }
  
}

@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;