@import './less-func.less';
@import './index.less';
@import "./offer_pdf.less";

body {
  // min-width: 1280px;
  min-width: 1050px;
  overflow-y: hidden;
}

//文字不可复制
.non-copyable {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

// button,
// input,
// select,
// textarea,
// input:focus,
// textarea:focus {
//   /* 去掉轮廓 */
//   outline: none;
//   /* 禁止拖拽 */
//   resize: none;
// }
/* 去掉 table cell 的边距并让其边重合 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 去除默认边框 */

fieldset,
img {
  border: none;
}

/* 格式化ul,ol,li,去掉默认的样式 */

ol,
ul {
  list-style: none;
}

/* 默认不显示下划线，保持页面简洁 */

ins,
a {
  text-decoration: none;
}

// Float 系列
/* 清除浮动 */

.clearfix::after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

/* 设置浮动，减少浮动带来的 bug */

.float-left,
.float-right {
  display: inline;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

// Font 系列
.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 400;
}

.font-bolder {
  font-weight: bolder;
}

.h-100 {
  height: 100%;
}

// 溢出样式
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

// 省略号系列
/* 一行文字超出省略号 */

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 两行文字超出省略号 */

.two-line {
  .more-line(@line: 2)
}

.ellipsis {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.widthAuto {
  width: auto !important;
}

.min-width50 {
  min-width: 50px;
}

// // 滚动条样式
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  // background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 px rgba(0, 0, 0, 0.3);
  // background-color: #fff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: @color-blue-2;
  border-radius: 5px;
}

.menu-box{
  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    background-color: @color-blue-2; 
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 px rgba(0, 0, 0, 0.3);
    background-color: @color-blue-2;
    border-radius: 5px;
  }
  
 
}

.nav-box{
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: #f5f5f5;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 1px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color:rgba(#fefefe, 0.3);
    border-radius: 1px;
  }
}

// Display 布局方式
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.translateX-50 {
  transform: translateX(50%);
}

.translateX-70 {
  transform: translateX(70%);
}

// Flex布局样式 
.flex-col {
  display: flex;
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-items-auto {
  justify-items: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.cur-pointer {
  cursor: pointer;
}

.cur-copy {
  cursor: copy;
}

// 通用原子类
// Padding
// 0
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

// 10
.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

// 10
.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

// 15
.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-15 {
  padding-right: 15px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

// 20
.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

// 30
.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pr-77 {
  padding-right: 77px;
}

.pr-2 {
  padding-right: 2%;
}

.pt-40 {
  padding-bottom: 40px;
}

// Margin
// 0
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

// 10
.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

// 10
.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

// 15
.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

// 20
.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

// 30
.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}
.ml-135 {
  margin-left: 135px;
}

.mr-30 {
  margin-right: 30px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.border-r-16 {
  border-radius: 16px;
}

//打死不换行
.white-space {
  white-space: nowrap;
}

//tab按钮样式
.tab-item {
  border-radius: 5px;
  margin-left: 0px !important;
  margin-right: 5px;
  background-color: rgb(169, 188, 245);
}

//tab选中样式
.tab-active {
  background: #00f !important;
}

//点击样式
.label-span {
  color: #00f;
  cursor: pointer;
}
.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}





.rect-printElement-types .hiprint-printElement-type > li > ul > li > a{
  height: 28px !important;
  line-height: 28px !important;
  color: #333 !important;
  padding: 0 !important;
  margin-left: 3px !important;
}


.detail-box-height{
  height: calc(100vh - 120px);
  overflow-y: scroll;
}




// 修改 页眉/页脚线 样式
.hiprint-headerLine, .hiprint-footerLine {
  border-color: red !important;
  z-index: 99999;
}

.hiprint-headerLine:hover, .hiprint-footerLine:hover {
  border-top: 3px dashed red !important;
  z-index: 99999;
}

.hiprint-headerLine:hover:before {
  content: "页眉线";
  left: calc(50% - 18px);
  position: relative;
  background: #FFFF;
  top: -12px;
  color: red;
  font-size: 12px;
  z-index: 99999;
}

.hiprint-footerLine:hover:before {
  content: "页脚线";
  left: calc(50% - 18px);
  position: relative;
  color: red;
  background: #FFFF;
  top: -12px;
  font-size: 12px;
  z-index: 99999;
}