.toolbar {
    height: 58px;
    background-color: #fff;
    padding: 3px 12px;
    margin: 16px 0 8px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .modules-name {
        display: inline-block;
        font-weight: 400;
        color: #7d7d7d;
        min-width: 120px;
        margin-right: 15px;
        padding-top: 2px;
    }

    /*订单搜索*/

    .pub-search-box {
        display: inline-block;
        flex: 1;
        vertical-align: top;
    }

    .pub-search-right {
        display: inline-block;
        margin-left: 14px;
        .header-search {
            width: 60px;
            // height: 32px;
            background: #4165e3;
            border-radius: 4px;
        }
        .header-reset {
            width: 60px;
            // height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e8e8e8;
			padding: 0;
        }
        .header-top {
            width: 80px;
            // height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e8e8e8;
        }
    }

    // .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     min-height: 26px;
    // }
}


.advance{
    width: 360px;
    height: 100vh;
}
@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;