.layout-sider {
    background-color: #262c44;
    .ant-menu {
        background-color: #262c44;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a1a4af;
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        img {
            width: 36px;
            height: 28px;
        }
        span {
            color: #fff;
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 16px;
            font-weight: 400;
            margin-left: 5px;
        }
    }
    &-logo {
        box-sizing: border-box;
        display: flex;
        color: #238ff2;
        display: flex;
        font-weight: 400;
        align-items: flex-end;
        justify-content: center;

        &-box {
            padding-bottom: 6px;
            display: flex;
            align-items: flex-end;
            line-height: 1;
            width: 100%;
        }

        &-img {
            height: 42px;
            margin-right: 8px;
        }
    }

    &-shop {
        position: relative;
        overflow: hidden;
        padding: 0 15px;
        height: 55px;
        line-height: 55px;
        font-size: 22px;
        color: #2e3aeb;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-menu {
        // margin-top: 55px;
        overflow-x: hidden;
        overflow-y: auto;
        // height: calc(100vh - 120px - 64px);

        &-button {
            margin: 16px auto !important;
            width: 150px !important;
        }

        &-button--small {
            margin: 16px auto !important;
            width: 60px !important;
            min-width: 60px;
        }
    }
}

.layout-section {
    overflow-y: auto;
    &-header {
        box-sizing: border-box;
        height: 48px;
        padding: 10px 30px 10px 0px;
        display: flex;
        line-height: 1;
        // justify-content: space-between;
        justify-content: flex-end;
        align-items: center;
        box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
        z-index: 1;
        background-color: #fff;

        .header-left {
            cursor: pointer;
            padding: 10px;
            font-size: 18px;
        }

        .header-right {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;

            .header-right-item + .header-right-item {
                margin-left: 10px;
            }

            .config-icon {
                width: 32px;
                color: #7d7d7d;
                background: #ffffff !important;
                border: 1px solid #7d7d7d;
                cursor: pointer;
            }

            .user-info {
                color: #02a7f0;
                cursor: pointer;
                vertical-align: middle;

                span.account {
                    vertical-align: middle;
                }
            }

            .user-icon {
                margin-right: 10px;
            }

            .logout-icon {
                color: #d82007;
                background-color: #ffffff;
                border: none;
                cursor: pointer;
                font-size: 18px;
            }

            .header-img {
                margin-right: 24px;
                width: 16px;
                height: 16px;
                cursor: pointer;
            }
        }
    }

    &-nav {
        min-height: 34px;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        background-color: #fff;
    }

    &-content {
        margin: 18px 18px 16px 18px;
    }
}

//nav
.nav-box {
    display: flex;
    background-color: #fff;
    position: sticky !important;
    .nav-icon {
        width: 34px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 3px 0px #ebebeb;
        // box-shadow: 0px 3px 3px 0px rgba(33, 34, 34, 0.12);
        font-size: 12px;
        color: #73798e;
        cursor: pointer;
        line-height: 34px;
        text-align: center;
    }
    .nav-center {
        flex: 1;
        overflow-x: scroll;
        overflow-y: hidden;
        box-sizing: border-box;
        margin: 0 3px;

        .nav-center-box {
            display: flex;
            float: left;
            min-width: 100%;
        }
        .nav {
            display: inline-block;
            // font-size: 12px !important;
        }
    }
    .ant-tag {
        border-radius: 0 !important;
        position: relative;
        padding: 0px 26px 0 10px;
        cursor: pointer;
        min-width: 90px;
        box-shadow: 0px 3px 3px 0px rgba(33, 34, 34, 0.12);
        border: none;
        background: #ffffff;
        box-shadow: 1px 0px 0px 0px #f4f4f4;
        height: 34px;
        line-height: 34px;
        &-close-icon {
            position: absolute;
            width: 10px;
            height: 10px;
            top: 12px;
            right: 8px;
            &:hover {
                color: red;
            }
        }
        &.tag-checked,
        &:hover,
        &:focus,
        &:active {
            color: #4062d8;
        }
    }
}

//dict
.dict-container {
    position: relative;
    padding: 0 16px 10px;
    height: calc(100vh - 140px);

    .dict-list-box {
        display: inline-block;
        width: 350px;
        height: 100%;
        min-height: 500px;
        position: relative;
        //border: 2px solid #ebebec;
        margin-right: 1%;
        vertical-align: top;
        overflow-y: auto;
        padding: 13px 0 0 3px;
    }

    .dict-list-title {
        color: rgba(22, 27, 38, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        font-weight: bold;
        text-align: left;
    }

    .dict-tree-box{
        height: calc(100% - 106px);
        overflow-y: auto;
    }

    .dict-from-box {
        flex: 1;
        height: 100%;
        min-height: 500px;
        position: relative;
        overflow-y: auto;
        background: #fff;
        //border: 2px solid #ebebec;
        vertical-align: top;
        flex-direction: column;

        .dict-list-header {
            padding: 16px 0 0 16px;
        }

        .dict-from-content {
            padding: 30px;
        }
        .dict-from-title {
            color: rgba(22, 27, 38, 1);
            font-size: 16px;
            font-family: PingFangSC-Medium;
            text-align: left;
            font-weight: 600;
        }

        .dict-init-box {
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
        }
    }
}
//通用 左右布局
.use-warp {
    position: relative;
    padding: 0 16px 10px;
    height: calc(100vh - 284px);
    display: flex;

    & > div {
        height: 100%;
        min-height: 300px;
        position: relative;
        border: 2px solid #ebebec;
        background: #fff;
        vertical-align: top;
        overflow-y: hidden;
    }

    & > div:first-child {
        margin-right: 1%;
        padding: 23px;
        overflow: auto;
    }

    & > div:last-child {
        flex-direction: column;
        flex: 1;

        .header-content {
            padding: 16px;
            white-space: nowrap;
        }

        .list-content {
            height: calc(100% - 64px);
            position: relative;
            overflow-y: auto;
        }
    }
}

//图片列表
.img-file-box {
    position: relative;
    width: 120px;
    height: 120px;
    border: 1px solid #aaa;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;

    span.ant-badge {
        width: 100%;
    }

    div.ant-image {
        width: 100%;
        text-align: center;
        height: 120px;
        line-height: 120px;
        overflow: hidden;
    }
}

//重设置图片的col 栅格
.file-image-box {
    .ant-form-item-label {
        flex: 0 0 6.333333%;
        max-width: 6.333333%;
    }
}

//点选表格得每行样式*/
.table-select-row,
.table-select-row > .ant-table-cell-fix-left {
    color: #2e3aeb;
    background: #e9ecfb;
}
.ant-table-selection {
    display: flex;
    flex: auto;
    flex-direction: column-reverse;
}
//tabs 按钮选中的样式
.tab-buts-active {
    background-color: #2e3aeb;
}

//详情页贴下边的展示框
.details-bot-fixed-warp {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px -3px 0px 0px rgba(33, 34, 34, 0.1);
    line-height: 60px;
    transition: all 0.3s;
}

//详情备注 col 栅格
.detail-note-col {
    .ant-form-item-label {
        display: block;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

//详情备注 全部栅格
.detail-all-col {
    .ant-form-item-label {
        display: block;
        flex: 0 0 6.22%;
        max-width: 6.22%;
    }
}
