.index-content {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
}
.index-content-title-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 32px;
    .index-content-title-bar-title {
        flex: 1;
        font-weight: 500;
        font-size: 16px;
        color: #202123;
        line-height: 22px;
    }
    .index-content-title-bar-more {
        font-size: 14px;
        font-weight: 400;
        color: #9698a3;
        margin-left: 20px;
        cursor: pointer;
    }
}

.index-header {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.quickEntrance-modal-list {
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 100px;
        height: 100px;
        padding-top: 20px;
        text-align: center;
        cursor: pointer;
        margin: 0 2px 2px 0;
        &.select {
            background-color: #e6ffee;
            position: relative;
            &:after {
                position: absolute;
                right: 10px;
                top: 10px;
                display: table;
                width: 7px;
                height: 12px;
                border: 2px solid #00d10a;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: ' ';
            }
        }
        .icon {
            width: 36px;
            height: 36px;
            margin: 0 auto;
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
            background-color: #fff;
            border-radius: 12px;
        }
        .name {
            height: 18px;
            margin-top: 6px;
            font-weight: 400;
            font-size: 12px;
            color: #333333;
            line-height: 18px;
        }
    }
}

.home-title-menu {
    display: flex;
    align-items: center;
    .tabs {
        display: flex;
        align-items: center;
        margin: 0 16px;
        .tab {
            width: 60px;
            height: 32px;
            border: 1px solid #e2e5e8;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: #34384a;
            line-height: 22px;
            margin-left: -1px;
            &.active {
                background-color: #4165e3;
                border-color: #4165e3;
                color: #fff;
            }
            &:first-child {
                border-radius: 4px 0 0 4px;
            }
            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }
    }
}

.home {
    padding: 18px 18px 16px 18px;
    .ant-btn {
        padding: 0;
        position: relative;
        &::after {
            content: '▲';
            position: absolute;
            top: 0;
            right: 30px;
            cursor: pointer;
        }
    }
    &.full {
        height: calc(100vh - 84px);
        overflow-y: auto;
        .index-header {
            height: calc(13% - 16px);
            .statistics-item {
                height: calc(100% - 20px);
                padding-top: 0;
                &::before {
                    content: '';
                    height: 30%;
                    display: block;
                }
            }
        }

        .content-top {
            height: calc(50% - 16px);
            & > .left {
                width: 400px;
                height: 100%;
            }
            .messageBox {
                height: 220px;
                .list {
                    margin-top: 20px;
                }
            }
            .quickEntrance {
                height: calc(100% - 236px);
                .list {
                    max-height: calc(100% - 52px);
                }
            }
            .lineChart {
                height: 100%;
                .ant-spin-nested-loading {
                    height: calc(100% - 30px);
                }
                .ant-spin-container {
                    height: 100%;
                }
                .content {
                    height: 100%;
                }
                .chart {
                    height: calc(100% - 130px);
                }
            }
        }
        .content-bottom {
            height: 37%;
            .pieChart {
                height: 100%;
                .ant-spin-nested-loading {
                    height: calc(100% - 42px);
                }
                .ant-spin-container {
                    height: 100%;
                }
                .content {
                    height: 100%;
                }
                .chart {
                    height: 100%;
                }
            }
            .pieCavityChart {
                height: 100%;
                .ant-spin-nested-loading {
                    height: calc(100% - 42px);
                }
                .ant-spin-container {
                    height: 100%;
                }
                .content {
                    height: 100%;
                }
                .chart {
                    height: 100%;
                }
            }
        }
    }
}

@color-blue-1: #3E67F4;@color-blue-2: #3E67F4;@color-blue-3: #3E67F4;@color-blue-4: #3E67F4;@color-blue-5: #3E67F4;@color-blue-6: #3E67F4;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@color-bg: #F8F8F8;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;