// Color
@color-bright-1: #ffffff;
@color-blue-1: #f9faff;
@color-blue-2: #e9ecfb;
@color-blue-3: #d7defe;
@color-blue-4: #bdc7fc;
@color-blue-5: #2e3aeb;
@color-dark-0: #d6d6d6;
@color-dark-1: #7d7d7d;
@color-dark-2: #6b6b6b;
@color-dark-3: #555555;
@color-dark-4: #434343;
@color-dark-5: #29292c;
@color-dark-6: #010002;
@color-red: #ff0b00;
@color-green: #028e19;
// Background
@background-color-primary: @color-blue-2;
@background-color-secondary: @color-blue-2;
@background-color-tertiary: @color-blue-5;
@background-body: @color-bg;
body {
    background-color: @background-body;
}

// Font Color
// @font-color-default: @color-dark-1;
@font-color-default: #323232;
@font-color-primary: @color-dark-4;
@font-color-secondary: @color-dark-3;
@font-color-active: #ffffff;
@font-family: 'MicrosoftYaHeiLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@text-color: @font-color-default;
// Base Scaffolding Variables
// ---
// @font-size-base: 16px;
// @font-size-lg: 18px;
// @font-size-sm: 14px;
// Border
// @border-radius-base: 5px;
// @border-color-base: @color-blue-3; // base border outline a component
// // Layout
// @layout-body-background: @background-body;
// @layout-header-background: @color-bright-1;
// @layout-header-height: 64px;
// @layout-header-padding: 15px 10px;
// .ant-layout {
//   &-header {
//     box-sizing: border-box;
//   }
//   &-sider {
//     padding-right: 1px;
//   }
//   &-content {
//     // height: calc(100vh - 80px);
//     height: calc(100vh - 124px);
//     overflow: hidden;
//     overflow-y: auto;
//   }
// }

// .ant-col-h-auto {
//   height: auto;
//   .ant-col {
//     height: auto;
//   }
// }

.editable-row .ant-col {
    height: auto;
    margin-top: 0;
}

// // Menu
// ---
@menu-selected-bg: @color-blue-2;
@menu-item-active-bg: @color-blue-2;
@menu-highlight-color: @font-color-active;
@menu-item-font-size: @font-size-sm;
@menu-collapsed-width: 80px;
@menu-inline-toplevel-item-height: 45px;
// 默认菜单样式
.ant-menu {
    font-weight: 400;
    border-right: none;
    font-size: @font-size-base;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
    &-item {
        a {
            color: #a1a4af;
        }
    }
    &-item-selected {
        a {
            color: #fff;
        }
    }
    &-item-active {
        background-color: @menu-item-active-bg;
    }
    .anticon {
        font-size: @font-size-base;
    }
    &-submenu &-item {
        font-size: @font-size-sm;
    } // 菜单栏选择下样式
    &-submenu-selected {
        .ant-menu-submenu-title {
            // background: @menu-selected-bg;
        }
        .ant-menu-submenu-arrow {
            &::after {
                background-image: none !important;
                background-color: @menu-highlight-color !important;
            }
            &::before {
                background-image: none !important;
                background-color: @menu-highlight-color !important;
            }
        }
    } // 菜单鼠标移入样式
    &-submenu-active {
        .ant-menu-submenu-title {
            background: @menu-selected-bg;
        }
    } // 菜单栏标题右侧图标
    // &-submenu-arrow {
    //   // transform: none !important;
    //   &::before {
    //     width: 4px !important;
    //     height: 1px !important;
    //     transform: none !important;
    //   }
    //   &::after {
    //     width: 4px !important;
    //     height: 1px !important;
    //     transform: rotate(90deg) translateX(0px) !important;
    //   }
    // } // 菜单栏打开下样式
    &-submenu-open {
        .ant-menu-submenu-arrow {
            ::before {
                // background-image: none !important;
                background-color: @menu-highlight-color !important;
            }
            &::after {
                // background: none !important;
            }
        }
    } //选中右侧竖杠
    &-inline {
        .ant-menu-item::after {
            border-right: 3px solid #bdc7fc;
        }
    }
}

.ant-menu-item,
.ant-menu-submenu-title {
    padding-left: 37px !important;
    transition: cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-radio-button-wrapper:first-child {
    border-radius: 10px 0 0 10px;
}
.ant-radio-button-wrapper:last-child {
    border-radius: 0 10px 10px 0;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #4165e3;
    border-color: #4165e3;
}
// Buttons
@btn-font-size: @font-size-base;
@btn-font-weight: 400;
@btn-border-radius: 12rpx;
// @btn-default-color: @color-dark-2;
// @btn-default-border: @color-blue-3;
@btn-primary-color: #fff;
@btn-primary-bg: #4062d8;
@btn-link-bg: @background-color-primary;
@btn-link-hover-bg: @background-color-tertiary;
@btn-link-color: @color-bright-1;
@btn-link-hover-color: @color-bright-1;
.ant-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: @btn-font-size;
    // padding: 6px 26px;
    // min-width: 88px;
    vertical-align: middle;
    height: 32px;
    padding: 0 12px;
    border-radius: 4px;
    &:hover,
    &:active,
    &:focus {
        opacity: 0.8 !important;
        color: @btn-default-color;
        border-color: @btn-default-border;
    }
    &-primary {
        border: none;
        &:hover,
        &:active,
        &:focus {
            color: @btn-primary-color;
            background-color: @btn-primary-bg;
        }
    }
    &-link {
        background-color: @btn-link-bg;
        font-size: @font-size-sm;
        color: @btn-link-color;
        border: none;
        padding: 6px 16px;
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            // background: #90A4FE !important;
            background: #bdc7fc !important;
            color: #fff;
        }
        &:hover,
        &:active,
        &:focus {
            // background-color: @btn-link-hover-bg;
            // background-color: @btn-link-bg;
            background-color: blue;
            color: @btn-link-hover-color;
        }
    }
    &-round&-sm {
        height: 26px;
    }
    & + & {
        margin-left: 12px;
    }
}
.ant-table-thead > tr > th,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    position: relative;
    padding: 10px 10px;
    overflow-wrap: break-word;
}
.ant-table-tbody > tr > td {
    position: relative;
    padding: 2px 10px;
    overflow-wrap: break-word;
    height: 35px;
}
.ant-table-thead > tr > th {
    background: #d6d6d6 !important;
    font-weight: 600;
    border-bottom: 1px solid rgba(128, 128, 128, 0.307) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.307) !important;
}
// Tag
// @tag-default-bg: @color-bright-1;
// @tag-default-color: @color-blue-5;
// @tag-font-size: @font-size-sm;
// @tag-line-height: 1.5;
// @tag-border-radius: 10px;
// @tag-min-width: 90px;
// @tag-text-align: center;
// @tag-font-weight: 400;
// @tag-close-icon-width: 16px;
// @tag-close-icon-height: 16px;
// @tag-close-icon-top: -6px;
// @tag-close-icon-right: -6px;
// @tag-close-icon-bg: red;
// @tag-close-icon-color: @color-bright-1;
// @tag-close-icon-size: 12px;
// .ant-tag {
//   border-radius: @tag-border-radius !important;
//   text-align: @tag-text-align;
//   font-weight: @tag-font-weight;
//   position: relative;
//   margin: 0 5px;
//   padding: 6px 16px;
//   cursor: pointer;
//   min-width: 90px;
//   box-shadow: 0px 3px 3px 0px rgba(33, 34, 34, 0.12);
//   &-close-icon {
//     position: absolute;
//     width: @tag-close-icon-width;
//     height: @tag-close-icon-height;
//     top: @tag-close-icon-top;
//     right: @tag-close-icon-right;
//     background-color: @tag-close-icon-bg;
//     color: @tag-close-icon-color;
//     border-radius: 50%;
//     font-size: @tag-close-icon-size;
//     padding: 2px;
//     &:hover {
//       color: @color-bright-1;
//     }
//   }
//   &.tag-checked,
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: @background-color-tertiary;
//     color: @color-bright-1;
//   }
// }

// Table
// @table-header-bg: @color-blue-2;
// @table-header-color: #ffffff;
// @table-header-weight: 400;
// @table-body-weight: 400;
@table-font-size: 14px;
// @table-selected-row-color: @font-color-active;
// @table-selected-row-bg: #90A4FE;
// @table-selected-row-hover-bg: #90A4FE;
// // @table-row-hover-bg: @background-color-secondary;
// @table-row-hover-bg: #90A4FE;
@table-padding-vertical: 15px;
@table-padding-horizontal: 10px;
// @table-border-radius-base: 0px;
// @tree-node-hover-bg: #ffffff;
// .ant-table {
//   font-size: @font-size-sm;
//   font-weight: @table-body-weight;
//   line-height: @font-size-sm;
//   &-thead {
//     >tr {
//       >th {
//         font-weight: @table-header-weight;
//       }
//     }
//     th.ant-table-column-sort {
//       background: @table-header-bg ;
//     }
//   }
//   &-tbody {
//     /*偶数行*/
//     & tr:nth-of-type(even) {
//       background: #fff;
//     }
//     /*奇数行*/
//     & tr:nth-of-type(odd) {
//       background: #F5F7FF;
//     }
//     & tr>.ant-table-cell-fix-left,
//     & tr>.ant-table-cell-fix-right {
//       background: inherit;
//     }
//     & tr.ant-table-row-selected>td {
//       background: @color-blue-1 !important;
//     }
//     & tr:hover {
//       cursor: pointer;
//       color: #FFFFFF;
//     }
//     td.ant-table-column-sort {
//       background: inherit;
//     }
//   }
//   &-row-selected &-cell {
//     color: @font-color-active;
//   }
//   &-selection {
//     justify-content: center;
//   }
// }

// Pagination
// @pagination-item-min-width: 61px;
// @pagination-item-mini-min-width: 40px;
// @pagination-item-mini-margin-right: 6px;
// @pagination-item-radius: 16px;
// @pagination-item-size: 24px;
// .ant-pagination {
//   font-size: @font-size-sm;
//   &-item,
//   &.mini &-item {
//     border-radius: 16px;
//     &:hover,
//     &:active,
//     &:focus {
//       border: none;
//       background-color: @background-color-tertiary;
//       a {
//         color: #fff;
//       }
//     }
//   }
//   &-item {
//     min-width: @pagination-item-min-width;
//   }
//   &.mini &-item {
//     min-width: @pagination-item-mini-min-width;
//     margin-right: @pagination-item-mini-margin-right;
//   }
//   &-item-active {
//     border: none;
//     background-color: @background-color-tertiary;
//     &:hover a,
//     &:focus a,
//     &:active a,
//     a {
//       color:#fff;
//     }
//   }
//   &-item-link {
//     min-width: @pagination-item-min-width;
//     border-radius: 16px !important;
//     .anticon svg {
//       display: none;
//     }
//   }
//   &-next &-item-link,
//   &-prev &-item-link,
//   &.mini &-next &-item-link,
//   &.mini &-prev &-item-link {
//     position: relative;
//     background-color: @background-color-primary;
//     color: @color-bright-1;
//     border: none;
//     &::after {
//       position: absolute;
//       content: "\4e0a\4e00\9875";
//       left: 0;
//       right: 0;
//       top: 0;
//       bottom: 0;
//     }
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: @background-color-tertiary;
//       color: @color-bright-1;
//     }
//   }
//   &.mini &-next,
//   &.mini &-prev {
//     min-width: @pagination-item-mini-min-width;
//     margin-right: @pagination-item-mini-margin-right;
//   }
//   &-next &-item-link::after,
//   &.mini &-next &-item-link::after {
//     content: "\4e0b\4e00\9875";
//   }
//   &-options {
//     .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//       min-height: 24px !important;
//       height: 24px;
//     }
//     .ant-select-single .ant-select-selector .ant-select-selection-item {
//       line-height: 24px;
//     }
//     .ant-select-arrow {
//       top: 40%
//     }
//   }
// }

// Input
@input-hover-border-color: @color-blue-4;
@outline-color: @color-blue-4;
@placeholder-color: @color-blue-4;
@input-affix-margin: 6px;
@input-height-base: 32px;
// @input-border-color: @color-blue-3;
.ant-input {
    font-size: @font-size-sm;
    font-weight: 400;
    padding: 5px;
    line-height: 22px;
    border: 1px solid #d9d9d9;
    color: #000;
    &-round&-affix-wrapper {
        border-radius: 16px;
        line-height: 18px;
        padding: 0px 11px !important;
        border: 1px solid #d9d9d9;
        height: 33px !important;
    }
    // &-affix-wrapper>& {
    //   padding: 1px;
    // }
    &::placeholder {
        // color: @placeholder-color;
    }
    &:hover {
        border: 1px solid #ebebeb;
    }
    &:focus,
    .ant-input-focused {
        box-shadow: none;
    }
}
.ant-tree .ant-tree-treenode {
    padding: 0 0 10px 0;
}
//InputNumber
// .ant-input-number-input-wrap {
//   & input {
//     padding-bottom: 3px;
//   }
//   & input::placeholder {
//     font-size: 14px;
//     color: @placeholder-color;
//   }
// }
// input:-internal-autofill-selected {
//   -webkit-text-fill-color: #000 !important;
//   transition: background-color 5000s ease-in-out 0s !important;
// }
// Select
@select-item-selected-bg: @background-color-secondary;
@select-item-active-bg: @background-color-secondary;
@select-item-selected-color: @font-color-active;
@select-placeholder-color: @color-blue-4;
@select-dropdown-font-size: @font-size-sm;
.pub-search-box .ant-input {
    font-size: @font-size-sm;
}
.ant-select {
    font-size: @font-size-sm;
    font-weight: 400;
    border-radius: 5px;
    width: 100%;
    vertical-align: middle; // min-height: 34px;
    &-single&-sm:not(.ant-select-customize-input) .ant-select-selector {
        height: 34px;
        line-height: 34px;
        border-radius: 4px;
        border: 1px solid #d5d5d5;
    }
    &:not(.ant-select-customize-input) .ant-select-selector {
        min-height: 34px; // min-width:212px;
    }
    &-round&:not(&-customize-input) &-selector {
        // border-radius: 16px;
    }
    &-round &::placeholder {
        // color: @placeholder-color;
    }
    &-selection-placeholder {
        // color: @select-placeholder-color;
        line-height: 32px;
    }
    &-item-option-active:not(.ant-select-item-option-disabled) {
        color: #ffffff;
    }
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 34px;
}
.ant-select-multiple .ant-select-selector {
    max-height: 50px;
    overflow-y: auto;
}
//防止下拉框换行
// .ant-select-selection-item {
//   white-space: inherit;
// }

// // Picker
// .ant-picker {
//   width: 100%;
//   font-size: @font-size-sm;
//   padding: 4px 11px;
//   vertical-align: middle; // min-height: 34px;
//   &-small {
//     padding: 1px 11px;
//   }
//   &-round {
//     border-radius: 16px;
//   }
//   &-input>input {
//     font-size: @font-size-sm;
//     &::placeholder {
//       color: @placeholder-color;
//     }
//   }
//   &-dropdown {
//     font-size: @font-size-sm;
//   }
// }

// Checkbox
// .ant-checkbox {
//   &-inner {
//     border-radius: 2px;
//   }
//   &-inner-text {
//     font-size: @font-size-sm;
//   }
// }

// Drawer
// @drawer-mask-bg: transparent;
// .ant-drawer {
//   &-mask {
//     background: @drawer-mask-bg;
//   }
//   &-header {
//     padding: 14px 17px;
//   }
//   &-close {
//     padding: 17px;
//   }
//   &-title {
//     text-align: left;
//   }
//   &-body {
//     padding: 14px 17px;
//   }
//   &-content-wrapper {
//     padding-left: 30px;
//     background: rgba(0, 0, 0, 0.3);
//   }
// }
.ant-input-affix-wrapper-sm {
    padding: 0 7px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
}

.table-status-tag {
    display: inline-flex;
    height: 22px;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    align-items: center;
    justify-content: center;

    &.DarkGreen {
        background-color: rgba(0, 100, 0, 0.1);
        color: #006400;
    }
    &.ForestGreen {
        background-color: rgba(34, 139, 34, 0.1);
        color: #228b22;
    }
    &.OliveDrab {
        background-color: rgba(107, 142, 35, 0.1);
        color: #6b8e23;
    }
    &.Chartreuse {
        background-color: rgba(102, 205, 0, 0.1);
        color: #66cd00;
    }
    &.Green {
        background-color: rgba(0, 205, 0, 0.1);
        color: #00cd00;
    }
    &.Brown {
        background-color: rgba(165, 42, 42, 0.1);
        color: #a52a2a;
    }
    &.DarkGoldenrod {
        background-color: rgba(139, 101, 8, 0.1);
        color: #8b658b;
    }
    &.Orange {
        background-color: rgba(255, 165, 0, 0.1);
        color: #ffa500;
    }
    &.IndianRed {
        background-color: rgba(205, 92, 92, 0.1);
        color: #cd5c5c;
    }
    &.Chocolate {
        background-color: rgba(238, 118, 33, 0.1);
        color: #ee7621;
    }
    &.Maroon {
        background-color: rgba(176, 48, 96, 0.1);
        color: #b03060;
    }
    &.DarkViolet {
        background-color: rgba(148, 0, 211, 0.1);
        color: #9400d3;
    }
    &.DeepPink {
        background-color: rgba(255, 20, 147, 0.1);
        color: #ff1493;
    }
    &.CornflowerBlue {
        background-color: rgba(100, 149, 237, 0.1);
        color: #6495ed;
    }
    &.Blue {
        background-color: rgba(0, 0, 255, 0.1);
        color: #0000ff;
    }
}

.table-total-box {
    font-size: 14px;
    line-height: 1;
    .title {
        font-weight: bold;
    }
    .green {
        color: #028e19;
        font-size: 110%;
        font-weight: bold;
    }
    .red {
        color: #ff0b00;
        font-size: 110%;
        font-weight: bold;
    }
}
